<template>
  <v-dialog v-model="dialog" :width="width" @click:outside="cancel">
    <v-card :loading="loading">
      <EmployeeListItem
        :employee="employee"
        class="px-6 pt-4 pb-1"
        @click="onEmployeeSwap"
      >
        <template #action>
          <v-icon size="28" class="mr-1">$swapHorizontal</v-icon>
        </template>
      </EmployeeListItem>
      <v-card-text class="pt-5">
        <VxBtn
          v-if="employee.isOnBreak"
          class="mb-2"
          large
          block
          :disabled="loading"
          @click="onEndBreak"
        >
          <v-icon left>$coffeeOff</v-icon>
          {{ $t("auth.teamMode.employeeTimeSheetDialog.endBreakText") }}
        </VxBtn>
        <template v-else-if="employee.clockedIn">
          <VxBtn
            class="mb-2"
            large
            block
            :disabled="loading"
            @click="onClockOut"
          >
            <v-icon left>$clockEnd</v-icon>
            {{ $t("auth.teamMode.employeeTimeSheetDialog.clockOutText") }}
          </VxBtn>
          <VxBtn large block :disabled="loading" @click="onStartBreak">
            <v-icon left>$coffee</v-icon>
            {{ $t("auth.teamMode.employeeTimeSheetDialog.startBreakText") }}
          </VxBtn>
        </template>
        <VxBtn v-else large block :disabled="loading" @click="onClockIn">
          <v-icon left>$clockStart</v-icon>
          {{ $t("auth.teamMode.employeeTimeSheetDialog.clockInText") }}
        </VxBtn>
      </v-card-text>
      <v-slide-y-transition>
        <v-card-text v-if="errorMessage">
          <VxAlert type="error">
            {{ errorMessage }}
          </VxAlert>
        </v-card-text>
      </v-slide-y-transition>
      <v-card-actions>
        <v-spacer />
        <VxBtn text @click="cancel">
          {{ $t("auth.teamMode.employeeTimeSheetDialog.cancelText") }}
        </VxBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { VxBtn, VxAlert } from "@/core-ui";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import EmployeeListItem from "./EmployeeListItem.vue";
import {
  PUNCH_IN,
  PUNCH_OUT,
  START_BREAK,
  END_BREAK,
} from "@/graphql/employee/mutations.gql";

export default {
  name: "EmployeeTimeSheetDialog",
  components: { VxBtn, VxAlert, EmployeeListItem },
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,
  props: {
    width: {
      type: [String, Number],
      default: 350,
    },
  },
  data: () => ({
    loading: false,
    errorMessage: null,
  }),
  computed: {
    ...mapGetters("auth", ["employeeAccessStatus"]),
    employee() {
      return this.$store.state.teamMode.employee;
    },
    dialog: {
      get() {
        return this.$store.state.teamMode.timeSheetDialog;
      },
      set(value) {
        this.$store.commit("teamMode/setTimeSheetDialog", value);
      },
    },
  },
  watch: {
    "$store.state.teamMode.timeSheetDialog"(newVal) {
      if (newVal) {
        // Reset error message when showing dialog
        this.errorMessage = null;
      }
    },
    employee() {
      // Remove error message if we get update of employee from outside (for ex. for subscription to employee status)
      this.errorMessage = null;
    },
    employeeAccessStatus(newVal) {
      if (newVal === "noEmployee") {
        this.hide();
      }
    },
  },
  methods: {
    async onClockIn() {
      try {
        this.loading = true;

        const response = await this.$apollo.mutate({
          mutation: PUNCH_IN,
          variables: { employeeId: this.employee.id },
        });

        const { errors, employee } = response.data.punchIn;

        if (errors?.length > 0) {
          throw new Error(
            this.$t(
              "auth.teamMode.employeeTimeSheetDialog.clockIn.errorMessage",
              { error: errors[0] }
            )
          );
        }

        this.$store.dispatch("teamMode/punchClock", employee);
        this.$emit("clockin");

        this.showSnackbar({
          text: this.$t(
            "auth.teamMode.employeeTimeSheetDialog.clockIn.successMessage",
            { fullName: this.employee.fullName }
          ),
        });
        this.hide();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async onClockOut() {
      const shouldClockOut = await this.$dialog.confirm({
        title: this.$t("auth.teamMode.employeeTimeSheetDialog.clockOut.title"),
        message: this.$t(
          "auth.teamMode.employeeTimeSheetDialog.clockOut.message"
        ),
      });

      if (!shouldClockOut) {
        return;
      }

      try {
        this.loading = true;

        const response = await this.$apollo.mutate({
          mutation: PUNCH_OUT,
          variables: { employeeId: this.employee.id },
        });

        const { errors } = response.data.punchOut;

        if (errors?.length > 0) {
          throw new Error(
            this.$t(
              "auth.teamMode.employeeTimeSheetDialog.clockOut.errorMessage",
              { error: errors[0] }
            )
          );
        }

        this.$store.dispatch("auth/lockTeamMode");

        this.showSnackbar({
          text: this.$t(
            "auth.teamMode.employeeTimeSheetDialog.clockOut.successMessage",
            { fullName: this.employee.fullName }
          ),
        });
        this.hide();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async onStartBreak() {
      try {
        this.loading = true;

        const response = await this.$apollo.mutate({
          mutation: START_BREAK,
          variables: { employeeId: this.employee.id },
        });

        const { errors } = response.data.startBreak;

        if (errors?.length > 0) {
          throw new Error(
            this.$t(
              "auth.teamMode.employeeTimeSheetDialog.startBreak.errorMessage",
              { error: errors[0] }
            )
          );
        }

        this.$store.dispatch("teamMode/startBreak");
        this.showSnackbar({
          text: this.$t(
            "auth.teamMode.employeeTimeSheetDialog.startBreak.successMessage",
            { fullName: this.employee.fullName }
          ),
        });
        this.$store.dispatch("auth/lockTeamMode");
        this.hide();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async onEndBreak() {
      try {
        this.loading = true;

        const response = await this.$apollo.mutate({
          mutation: END_BREAK,
          variables: { employeeId: this.employee.id },
        });

        const { errors } = response.data.endBreak;

        if (errors?.length > 0) {
          throw new Error(
            this.$t(
              "auth.teamMode.employeeTimeSheetDialog.endBreak.errorMessage",
              { error: errors[0] }
            )
          );
        }

        this.$store.dispatch("teamMode/endBreak");
        this.showSnackbar({
          text: this.$t(
            "auth.teamMode.employeeTimeSheetDialog.endBreak.successMessage",
            { fullName: this.employee.fullName }
          ),
        });
        this.hide();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit("cancel");
      this.hide();
    },
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
    onEmployeeSwap() {
      this.$store.dispatch("auth/employeeAccessNeeded");
    },
  },
};
</script>
